/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Hero from '../page-components/Fedxe/Hero';
import Objectifs from '../page-components/Fedxe/Objectifs';
import Indicators from '../page-components/Fedxe/Indicators';

// Style
import '../page-styles/Fedxe.scss';
import Chronogramme from '../page-components/Fedxe/Chronogramme';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Fedxe() {
  /* ********************************** HOOKS ********************************* */

  // localisation

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Fedxe" />
      <div className="fedxe">
        <Hero />
        <Objectifs />
        <Indicators />
        <Chronogramme />
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Fedxe", "Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Fedxe;
