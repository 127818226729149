/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';
import Image from '../../../shared/Image';

// assets
import Icon1 from '../../../images/fedxe/indicators/indicators-icon-0.svg';
import Icon2 from '../../../images/fedxe/indicators/indicators-icon-1.svg';
import Icon3 from '../../../images/fedxe/indicators/indicators-icon-2.svg';
import Icon4 from '../../../images/fedxe/indicators/indicators-icon-3.svg';
import Icon5 from '../../../images/fedxe/indicators/indicators-icon-4.svg';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Indicators() {
  /* ******************************** CONSTANTS ******************************* */
  const icons = [Icon1, Icon2, Icon3, Icon4, Icon5];
  const { t } = useTranslation();
  const { subTitle, list } = t('indicators', { returnObjects: true });

  /* ***************************** RENDER HELPERS ***************************** */
  function List() {
    return list.map(({ id, count, countText }, index) => {
      const backgroundColor = index % 2 === 0 ? '--bg-red' : '--bg-gray';
      return (
        <div
          key={id}
          className={`fedxe-indicators-item fedxe-indicators-item${backgroundColor}`}
        >
          <span className="fedxe-indicators-item__icon">
            <img src={icons[index]} alt="" aria-hidden="true" />
          </span>
          <span className="fedxe-indicators-item__count">{count}</span>
          <span className="fedxe-indicators-item__label">{countText}</span>
        </div>
      );
    });
  }

  /* ***************************** RENDER ***************************** */

  return (
    <Container className="fedxe-indicators">
      <SubTitle>{subTitle}</SubTitle>
      <Row align="stretch" justify="center">
        <Col xs={5} lg={2}>
          <Image src="indicators-img-1" alt="indicators section image" />
        </Col>
        <Col lg={4} className="indicators-img-2">
          <Image src="indicators-img-2" alt="indicators section image" />
        </Col>
        <Col lg={6}>
          <div className="fedxe-indicators-list">
            <List />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

Indicators.propTypes = {};

Indicators.defaultProps = {};

export default Indicators;
