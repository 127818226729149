/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// Local UI components
import Image from '../../../shared/Image';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Hero() {
  /* ******************************** CONSTANTS ******************************* */
  // localisation
  const { t } = useTranslation();
  const { title, paragraphs } = t('hero', { returnObjects: true });
  /* ***************************** RENDER HELPERS ***************************** */
  function Paragraphs() {
    return paragraphs.map(({ id, text }) => <p key={id}>{text}</p>);
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <div className="fedxe-hero">
      <div className="fedxe-hero-background-container">
        <Image src="fedxe/hero/hero-bg" alt="" />
      </div>
      <Container>
        <h1 className="fedxe-hero__title">{title}</h1>
        <div className="fedxe-hero-descriptions">
          <Paragraphs />
        </div>
      </Container>
    </div>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
