/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import SubTitle from '../../../shared/UIKit/SubTitle';

// Local UI components
// @TODO: Import other locally-made dependencies here

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Chronogramme() {
  /* ******************************** CONSTANTS ******************************* */
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */
  const { t } = useTranslation();

  const { subTitle, list, listColumns } = t('chronogramme', {
    returnObjects: true,
  });
  /* ********************************** HOOKS ********************************* */
  /* ***************************** LOCAL VARIABLES **************************** */
  /* ******************************** CALLBACKS ******************************* */
  /* ***************************** RENDER HELPERS ***************************** */
  function ListColumns() {
    return listColumns.map(({ id, label }) => (
      <div key={id} className="fedxe-chronogramme-column">
        <span className="fedxe-chronogramme-column__label">{label}</span>
      </div>
    ));
  }

  function List() {
    return list.map(({ id, text }) => (
      <span key={id} className="fedxe-chronogramme-item__text">
        {text}
      </span>
    ));
  }

  function ListItemDuration() {
    return list.map(({ id }, index) => (
      <span
        key={id}
        className={`fedxe-chronogramme-item__duration-bar duration-bar--${index}`}
      />
    ));
  }

  /* ******************************** RENDERING ******************************* */

  return (
    <div className="fedxe-chronogramme">
      <SubTitle>{subTitle}</SubTitle>
      <Container>
        <Row
          align="start"
          justify="start"
          className="fedxe-chronogramme-row"
          nogutter
        >
          <Col lg={6} xl={6} xxl={6} className="fedxe-chronogramme-list">
            <List />
          </Col>
          <Col lg={6} xl={6} xxl={6}>
            <div className="fedxe-chronogramme-columns">
              <ListColumns />
            </div>
            <div className="fedxe-chronogramme-list">
              <ListItemDuration />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Chronogramme.propTypes = {};

Chronogramme.defaultProps = {};

export default Chronogramme;
