/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useTranslation } from 'react-i18next';

// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';
import Image from '../../../shared/Image';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// assets
import Icon1 from '../../../images/fedxe/objectifs/objectifs-icon-0.svg';
import Icon2 from '../../../images/fedxe/objectifs/objectifs-icon-1.svg';
import Icon3 from '../../../images/fedxe/objectifs/objectifs-icon-2.svg';
import Icon4 from '../../../images/fedxe/objectifs/objectifs-icon-3.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Objectifs() {
  /* ******************************** CONSTANTS ******************************* */
  const icons = [Icon1, Icon2, Icon3, Icon4];
  // localisation
  const { t } = useTranslation();
  const { subTitle, description, list } = t('objectifs', {
    returnObjects: true,
  });

  /* ***************************** RENDER HELPERS ***************************** */
  function List() {
    return list.map(({ id, text }, index) => (
      <Col key={id} lg={6} className="fedxe-objectifs-item">
        <div className="fedxe-objectifs-item__icon">
          <img src={icons[index]} alt="" aria-hidden="true" />
        </div>
        <span className="fedxe-objectifs-item__text">{text}</span>
      </Col>
    ));
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <div className="fedxe-objectifs">
      <SubTitle>{subTitle}</SubTitle>
      <Container>
        <Row align="start" justify="center">
          <Col lg={6}>
            <p className="fedxe-objectifs__description">{description}</p>
            <Row
              align="start"
              justify="center"
              className="fedxe-objectifs-list"
            >
              <List />
            </Row>
          </Col>
          <Col lg={6}>
            <Image src="objectifs-img" alt="objectifs section image" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Objectifs.propTypes = {};

Objectifs.defaultProps = {};

export default Objectifs;
